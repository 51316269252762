<template>
  <v-container>
    <v-switch
        v-model="serviceActive"
        color="success"
        :label="serviceActive ? 'running' : 'off'"
    ></v-switch>
    <v-container fluid>
      <v-row align="center">
        <v-col
            class="d-flex"
            cols="12"
            sm="4"
        >
          <v-select
              :items="planets"
              v-model="planet"
              label="Select a planet"
          ></v-select>
        </v-col>

        <v-col
            class="d-flex"
            cols="12"
            sm="4"
        >
          <v-text-field
              v-model="cooldownPerMine"
              label="cooldown Per Mine"
          ></v-text-field>
        </v-col>
        <v-col
            class="d-flex"
            cols="12"
            sm="4"
        >
          <v-text-field
              v-model="threshold"
              label="Threshold (notify when price >)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            class="d-flex"
            cols="12"
            sm="4"
        >
          <v-btn
              @click="mined()"
              elevation="2"
          >Mined</v-btn>
        </v-col>
        <v-col
            class="d-flex"
            cols="12"
            sm="4"
        ><span>{{(Math.round(remainingCooldown) === remainingCooldown) ? remainingCooldown + '.0' : remainingCooldown}}</span></v-col>

      </v-row>
    </v-container>
  </v-container>
</template>

<script>

import axios from 'axios'
const src = require('@/assets/ding.mp3');

  export default {
    name: 'Main',

    mounted() {
      this.fetch()
    },
    data: () => ({
      planets : ["Veles", "Eyeke", "Kavian", "Magor", "Naron", "Neri"],
      cooldownPerMine: 0,
      cooldown: 0,
      threshold: 0,
      planet: "",
      elapsed: 0,
      miningData: {},
      timerId: null,
      polling: true,
      serviceActive: false,
      audio: new Audio(src),
    }),
    computed : {
      remainingCooldown() {
        let remaining = this.cooldown - this.elapsed
        return remaining > 0 ? remaining : 0
      },
    },
    methods: {
      fetch() {

        const api = () => {axios.get(`http://localhost/`).then((response) => {
          this.miningData = response.data
        }).catch( (error) => {
          console.log('err' + error)
        })}
        const timer = ms => new Promise(function (resolve) {
          setTimeout(() => {
            api();
            resolve();
          }, ms)
        })
        const vm = this;
        api();
        async function load () {
          for (;;) {
            if (false === vm.polling) {
              console.log('polling stopped')
              break;
            }
            await timer(3000);
          }
        }
        load();
      },
      notify() {
        // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
        const notification = {
          title: 'Time to Mine',
          options: {
            body: `Max mine on "${this.planet}" is now greater than "${this.threshold}"`,
            silent: false,
          },
          events: {
            onerror: function () {
              // console.log('Custom error event was called');
            },
            onclick: function () {
              // console.log('Custom click event was called');
            },
            onclose: function () {
              // console.log('Custom close event was called');
            },
            onshow: function () {
              // console.log('Custom show event was called');
            }
          }
        }
        // const audio = new Audio(this.audio);
        this.audio.play();
        this.$notification.show(notification.title, notification.options, notification.events)
      },
      mined() {
        const vm = this;
        clearInterval(vm.timerId);
        this.cooldown = this.cooldownPerMine
        let start = new Date().getTime();
        this.timerId = setInterval(() => {
          console.log('timer')
          let time = new Date().getTime() - start;
          vm.elapsed = Math.floor(time / 100) / 10;
          if (vm.remainingCooldown === 0 ) {
            console.log('cooldown finished')
            clearInterval(vm.timerId);
          }
        }, 100);
      }
    },
    watch: {
      miningData(val) {
        if (this.serviceActive && this.remainingCooldown === 0 && val[this.planet] > this.threshold) {
          this.notify();
        }
      },
    }
  }
</script>
